import React from "react";
import Listings from '../components/Listings';

const propertyListingsTemplate = props => {
  const { pageContext } = props
  
  return (
    <Listings listings={pageContext.listings} ctx={pageContext} />
  )
}
export default propertyListingsTemplate;
